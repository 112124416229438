import "$styles/index.scss"
import "$styles/syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"

import "./bootstrap.bundle.min.js"

console.info("Bridgetown is loaded!")

window.cycleHomeImage = function() {
  let homeImg = document.getElementById('home-img')

  let options = document.getElementsByTagName('image-url-storage')
  let urlList = []

  for (let idx = 0; idx < options.length; idx++) {
    let url = options[idx].getAttribute('url')
    urlList.push(url)
  }

  homeImg.src = urlList[Math.floor(Math.random() * urlList.length)]
}

if (document.getElementById('home-img')) {
  document.getElementById('home-img').onload = function () {
    let els = document.getElementsByClassName('home-img')
    for (let idx = 0; idx < els.length; idx++) {
      els[idx].classList.remove('hide')
    }
  }

  setTimeout(() => { cycleHomeImage() }, 500)
}
